<template>
    <div>
      <div class="card-content">
        <div class="content" style="display: grid; justify-content: center">
          <div style="width:500px;">
            <label class="label" for="">Payment plan ID:</label>
            <input type="text" class="input" v-model="enteredPaymentPlanId">
            <label class="label" for="">Confirm payment plan ID:</label>
            <input type="text" class="input" v-model="confirmPaymentPlanId">
            <p v-if="showCancelReason" style="width: 100%; color: red; font-weight: 700;">
                {{disableCancelReason}}
            </p>
            <button class="button is-primary"
                    style="margin-top: 8px;"
                    :disabled="disableCancel"
                    @click="showCancelPrompt">
              Cancel payment plan
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
<script>
export default {
  components: { },
  data() {
    const { paymentPlanId, tenant } = this.$route.params;
    return {
      paymentPlanId,
      tenant,
      enteredPaymentPlanId: '',
      confirmPaymentPlanId: '',
    };
  },
  props: {
    paymentPlan: Object,
  },
  methods: {
    showCancelPrompt() {
        if (confirm('Are you sure you would like to cancel this payment plan?')) { //eslint-disable-line
        const dataService = this.$dataService(this.tenant);
        dataService.paymentPlans.cancel(this.paymentPlanId);
      }
    },
  },
  computed: {
    disableCancelReason() {
      if (this.enteredPaymentPlanId === '') { return ''; }
      if (this.enteredPaymentPlanId !== this.confirmPaymentPlanId) { return 'IDs do not match.'; }
      if (this.enteredPaymentPlanId !== this.paymentPlanId) { return 'ID must match payment plan ID'; }

      return '';
    },
    showCancelReason() {
      return this.disableCancelReason !== '';
    },
    disableCancel() {
      return this.enteredPaymentPlanId !== this.confirmPaymentPlanId || this.enteredPaymentPlanId === '' || this.enteredPaymentPlanId !== this.paymentPlanId;
    },
  },
};
</script>
